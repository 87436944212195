import {
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { IChannel, Source } from "../types";
import { Dayjs } from "dayjs";

interface IProviderProps {
  children: ReactNode;
}

type Token = string | null;

type TDate = Record<"date_from" | "date_to", Dayjs | null>;

interface IUserContext {
  token: Token;
  setToken: Dispatch<SetStateAction<Token>>;

  telegramChannels: IChannel[];
  setTelegramChannels: Dispatch<SetStateAction<IChannel[]>>;

  rssChannels: IChannel[];
  setRssChannels: Dispatch<SetStateAction<IChannel[]>>;

  source: Source;
  setSource: Dispatch<SetStateAction<Source>>;

  date: TDate;
  setDate: Dispatch<SetStateAction<TDate>>;

  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;

  addresses: string[];
  setAddresses: Dispatch<SetStateAction<string[]>>;
}

const initialDate = {
  date_from: null,
  date_to: null,
};

export const UserContext = createContext<IUserContext>({
  token: null,
  setToken: {} as Dispatch<SetStateAction<Token>>,

  telegramChannels: [],
  setTelegramChannels: {} as Dispatch<SetStateAction<IChannel[]>>,

  rssChannels: [],
  setRssChannels: {} as Dispatch<SetStateAction<IChannel[]>>,

  source: Source.All,
  setSource: {} as Dispatch<SetStateAction<Source>>,

  date: initialDate,
  setDate: {} as Dispatch<SetStateAction<TDate>>,

  searchText: "",
  setSearchText: {} as Dispatch<SetStateAction<string>>,

  addresses: [],
  setAddresses: {} as Dispatch<SetStateAction<string[]>>,
});

export const UserContextProvider = ({ children }: IProviderProps) => {
  const [token, setToken] = useState<Token>(null);
  const [telegramChannels, setTelegramChannels] = useState<IChannel[]>([]);
  const [rssChannels, setRssChannels] = useState<IChannel[]>([]);
  const [source, setSource] = useState(Source.All);
  const [date, setDate] = useState<TDate>(initialDate);
  const [searchText, setSearchText] = useState("");
  const [addresses, setAddresses] = useState<string[]>([]);

  useEffect(() => {
    const _token = localStorage.getItem("token");

    if (_token) {
      setToken(_token);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        token,
        setToken,
        telegramChannels,
        setTelegramChannels,
        rssChannels,
        setRssChannels,
        source,
        setSource,
        date,
        setDate,
        searchText,
        setSearchText,
        addresses,
        setAddresses,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
