import { useContext, useMemo, useState } from "react";
import {
  // GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Source } from "../../types";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
// import clsx from "clsx";
import Dayjs from "dayjs";
import { Link } from "react-router-dom";
import { UserContext } from "../../context";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {
  FirstContainerSC,
  GridContainerWrapperSC,
  SecondContainerSC,
} from "./styled";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const getDate = (date: Date) =>
  Dayjs(date).add(3, "hour").format("HH:mm:ss DD/MM/YYYY");

export const generateLinkToMessage = (inner: string, link: string) => (
  <Link
    to={{
      pathname: `/message/${link}`,
    }}
    style={{ color: "inherit", textDecoration: "none" }}
  >
    {inner}
  </Link>
);

export const columns: GridColDef[] = [
  {
    headerName: "ID",
    field: "id",
    width: 300,
    renderCell: (params) => generateLinkToMessage(params.row.id, params.row.id),
    disableColumnMenu: true,
  },
  {
    headerName: "Дата",
    field: "publish_datetime",
    width: 200,
    description: "ЧЧ:ММ:СС ДД/ММ/ГГГ",
    disableColumnMenu: true,
    renderCell: (params) => getDate(params.row.publish_datetime),
  },
  {
    headerName: "Сообщение",
    description: "Первые 100 символов",
    field: "text",
    flex: 1.5,
    renderCell: (params) =>
      generateLinkToMessage(params.row.text, params.row.id),
    disableColumnMenu: true,

    // uncomment to see colored messages
    // cellClassName: (params: GridCellParams) => {
    //   const mood = params.row.mood;

    //   return clsx("message_preview", {
    //     negative: mood < 0,
    //     positive: mood > 0,
    //     neitral: mood === 0,
    //   });
    // },
  },
  {
    headerName: "Источник",
    field: "channel.title",
    description: "Адрес канала",
    flex: 0.5,
    renderCell: (params) => params.row.channel.title,
    disableColumnMenu: true,
    valueGetter: (params) => params.row.channel.title,
    sortComparator: (v1, v2) => v1?.localeCompare(v2),
  },
  {
    headerName: "Тип источника",
    field: "source",
    description: "RSS / ТГ-канал",
    flex: 0.3,
    renderCell: (params) =>
      params.row.channel.type === Source.RSS ? "RSS" : "Телеграм",
    disableColumnMenu: true,
  },
  {
    headerName: "Подписчики",
    field: "members_count",
    flex: 0.3,
    renderCell: (params) =>
      params.row.channel.type === Source.RSS
        ? ""
        : params.row.channel.members_count,
    disableColumnMenu: true,
  },
];

export const formatSourceValue = (ch:any) => {
  if (ch.type === Source.RSS) {
    return `rss: ${ch.title}`;
  }
  return `tg: ${ch.title} (${ch.members_count})`;

}

export const Toolbar = () => {
  const {
    setSource,
    source,
    date,
    setDate,
    searchText,
    setSearchText,
    addresses,
    setAddresses,
    rssChannels,
    telegramChannels,
  } = useContext(UserContext);

  const [selectAll,setSelectAll] = useState(false)

  const handleAddressChange = (event: SelectChangeEvent<typeof addresses>) => {
    const {
      target: { value },
    } = event;
    setAddresses(typeof value === "string" ? value.split(",") : value);
  };



  const handleCheckAllChange = () => {
    setSelectAll(!selectAll)
    setAddresses(selectAll ? [] : rssChannels.concat(telegramChannels).map((ch)=>ch.link))
  }

  const channels = useMemo(
    () => [
      ...rssChannels,
      ...telegramChannels,
    ],
    [rssChannels, telegramChannels]
  );


  return (
    <GridContainerWrapperSC>
      <GridToolbarContainer>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Тип источника
          </InputLabel>
          <Select
            label="Тип источника"
            value={source}
            onChange={(e) => setSource(e.target.value as Source)}
          >
            <MenuItem value={Source.All}>
              <em>Все</em>
            </MenuItem>
            <MenuItem value={Source.RSS}>RSS</MenuItem>
            <MenuItem value={Source.Telegram}>Телеграм</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 400 }}>
          <InputLabel id="demo-multiple-checkbox-label">Источник
          <Checkbox checked={selectAll} onChange={handleCheckAllChange} />
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={addresses}
            onChange={handleAddressChange}
            input={<OutlinedInput label="Источник" />}
            renderValue={(selected) => channels.filter((ch) => selected.includes(ch.link)).map((ch) => formatSourceValue(ch)).join(",")}
            MenuProps={MenuProps}
          >
            {channels.map((chan, i) => 
              <MenuItem key={i} value={chan.link}>
                <Checkbox checked={addresses.indexOf(chan.link) > -1} />
                <ListItemText
                  primary={formatSourceValue(chan)}
                  primaryTypographyProps={{ fontSize: "12px" }}
                />
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="От"
            value={date.date_from}
            maxDate={Dayjs()}
            inputFormat={"DD/MM/YYYY hh:mm:ss"}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            ampmInClock={false}
            ampm={false}
            onChange={(date_from) => {
              setDate({
                ...date,
                date_from,
              });
            }}
            renderInput={(params) => (
              <TextField style={{ width: "220px" }} {...params} />
            )}
          />
          <DateTimePicker
            label="До"
            value={date.date_to}
            minDate={date.date_from}
            maxDate={Dayjs()}
            inputFormat={"DD/MM/YYYY hh:mm:ss"}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            ampmInClock={false}
            ampm={false}
            onChange={(date_to) => {
              setDate({
                ...date,
                date_to,
              });
            }}
            renderInput={(params) => (
              <TextField style={{ width: "220px" }} {...params} />
            )}
          />
        </LocalizationProvider>
        <TextField
          sx={{ m: 1, minWidth: 200 }}
          label="Поиск"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    </GridContainerWrapperSC>
  );
};
