import {
  useState,
  useCallback,
  ChangeEventHandler,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { IChannel } from "../types";
import { useChannels } from "../hooks/useFetch";

interface IProps {
  open: boolean;
  type: string;
  handleClose: () => void;
  contentText?: string;
  defaultState?: IChannel;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const urlRegexp =
  // eslint-disable-next-line no-useless-escape
  /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;

const linkError = "Некорректный url";
const requireError = "Обязательное поле";

export const AddChannelModal = (props: IProps) => {
  const { open, handleClose, contentText, defaultState, type, setLoading } =
    props;

  const title = defaultState
    ? `Редактировать ${type === "rss" ? "RSS" : "Телеграм"} канал`
    : `Добавить ${type === "rss" ? "RSS" : "Телеграм"} канал`;

  const [state, setState] = useState<IChannel>(
    defaultState || ({} as IChannel)
  );
  const [linkErrMessage, setLinkErrMessage] = useState<string>("");
  const [titleErrMessage, setTitleErrMessage] = useState<string>("");

  const {
    saveChannel,
    updateChannel,
    fetchRssChannels,
    fetchTelegramChannels,
    deleteChannel,
  } = useChannels();

  useEffect(() => {
    if (defaultState) {
      setState(defaultState);
    }
  }, [defaultState]);

  useEffect(() => {
    if (linkErrMessage || titleErrMessage) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const validate = useCallback(() => {
    let isValid = true;

    if (!state.title) {
      isValid = false;
      setTitleErrMessage(requireError);
    } else {
      setTitleErrMessage("");
    }

    if (!state.link) {
      isValid = false;
      setLinkErrMessage(requireError);
    } else if (!urlRegexp.test(state.link) && !urlRegexp.test(state.link)) {
      isValid = false;
      setLinkErrMessage(linkError);
    } else {
      setLinkErrMessage("");
    }

    return isValid;
  }, [state]);

  const onClose = useCallback(() => {
    setLinkErrMessage("");
    setTitleErrMessage("");
    setState({} as IChannel);
    handleClose();
  }, [handleClose]);

  const onSave = useCallback(async () => {
    if (!validate()) {
      return;
    }

    defaultState?.id
      ? await updateChannel({
          ...state,
          id: defaultState?.id,
          type,
        })
      : await saveChannel({
          ...state,
          id: defaultState?.id,
          type,
        });

    type === "rss"
      ? fetchRssChannels(setLoading)
      : fetchTelegramChannels(setLoading);
    onClose();
  }, [
    validate,
    defaultState?.id,
    updateChannel,
    state,
    type,
    saveChannel,
    fetchRssChannels,
    setLoading,
    fetchTelegramChannels,
    onClose,
  ]);

  const onDelete = async () => {
    if (defaultState?.id) {
      await deleteChannel(defaultState.id);
    }
    type === "rss"
      ? fetchRssChannels(setLoading)
      : fetchTelegramChannels(setLoading);
    onClose();
  };

  const changeHandler: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (linkErrMessage || titleErrMessage) {
      validate();
    }
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        <TextField
          autoFocus
          inputProps={{ maxLength: 100 }}
          label="Наименование"
          variant="standard"
          margin="dense"
          type="text"
          id="title"
          name="title"
          fullWidth
          defaultValue={defaultState?.title}
          helperText={titleErrMessage}
          error={Boolean(titleErrMessage)}
          onChange={changeHandler}
        />
        <TextField
          inputProps={{ maxLength: 100 }}
          label="Адрес"
          variant="standard"
          margin="dense"
          type="url"
          id="link"
          name="link"
          fullWidth
          defaultValue={defaultState?.link}
          helperText={linkErrMessage}
          error={Boolean(linkErrMessage)}
          onChange={changeHandler}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
        {defaultState && (
          <Button color="error" onClick={onDelete}>
            Удалить
          </Button>
        )}
        <Button color="success" onClick={onSave}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
