import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Message } from "./components/Message";
import { Dashboard } from "./components/Dashboard";
import Navbar from "./components/Navbar/Navbar";
import { Rss } from "./components/Rss";
import { Telegram } from "./components/Telegram";
import { history, PrivateRoute } from "./components/PrivateRoute";
import { Login } from "./components/Login";
import { Datafiles } from "./components/Datafiles";

export const App = () => {
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/rss"
          element={
            <PrivateRoute>
              <Rss />
            </PrivateRoute>
          }
        />
        <Route
          path="/telegram"
          element={
            <PrivateRoute>
              <Telegram />
            </PrivateRoute>
          }
        />
        <Route
          path="/message/:messageId/"
          element={
            <PrivateRoute>
              <Message />
            </PrivateRoute>
          }
        />
        <Route
        path="/datafiles"
        element ={
          <PrivateRoute><Datafiles/></PrivateRoute>
        }/>
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};
