import { useContext, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { columns, Toolbar } from "./utils";
import { UserContext } from "../../context";
import { IMessage, Source } from "../../types";
import { NoRowsOverlay } from "../NoRowsOverlay";
import { LoadingOverlay } from "../LoadingOverlay";
import Dayjs from "dayjs";
import { useChannels } from "../../hooks/useFetch";
import { useNavigate } from "react-router";
import InfoIcon from "@mui/icons-material/Info";

export const Dashboard = () => {
  const { token, source, date, searchText, addresses } =
    useContext(UserContext);
  const [rows, setRows] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(true);
  const [schedulerPeriod, setSchedulerPeriod] = useState(0);

  const navigate = useNavigate();

  const { fetchRssChannels, fetchTelegramChannels } = useChannels();

  useEffect(() => {
    setLoading(true);
    const fetchMessages = async () => {
      const { date_from, date_to } = date;
      const strFrom = date_from
        ? Dayjs(date_from).subtract(8, "hour").toISOString()
        : null;
      const strTo = date_to
        ? Dayjs(date_to).subtract(8, "hour").toISOString()
        : null;

      const dateFrom = date_from ? `&date_from=${strFrom}` : "";
      const dateTo = date_to ? `&date_to=${strTo}` : "";

      const search_text = searchText ? `&search_text=${searchText}` : "";

      const channel_type =
        source && source !== Source.All ? `&channel_type=${source}` : "";

      try {
        let response = await fetch(
          `/api/message?size=100${dateFrom}${dateTo}${search_text}${channel_type}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        response = await response.json();

        //@ts-ignore
        if (response?.details) {
          navigate("/login");
        }

        if (Array.isArray(response)) {
          setRows(
            response
              .map((r: any) => ({
                ...r,
                publish_datetime: new Date(r.publish_datetime),
              }))
              .filter((r: any) =>
                !addresses.length ? true : addresses.includes(r.channel.link)
              )
          );
        }
      } catch (e) {
        navigate("/");
      }
      setLoading(false);
    };

    const fetchSettings = async () => {
      try {
        let response = await fetch("/api/settings", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        response = await response.json();
        //@ts-ignore
        setSchedulerPeriod(response?.scheduler_period);
      } catch (e) {
        navigate("/");
      }
    };

    fetchMessages();
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, date, searchText, addresses]);

  useEffect(() => {
    fetchRssChannels(setLoading);
    fetchTelegramChannels(setLoading);
  }, [fetchRssChannels, fetchTelegramChannels]);

  var deleteAllMessages = async () => {
    try {
      let response = await fetch("/api/allmessages",{
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },     
      })
      let respdata = await response.json()
    } catch(e) {
      navigate("/")
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        // uncomment to see colored messages
        //   "& .negative": {
        //     color: "red",
        //   },
        //   "& .positive": {
        //     color: "green",
        //   },
      }}
    >
      {Boolean(schedulerPeriod) && (
        <p
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <InfoIcon color="info" /> Период опроса каналов: {schedulerPeriod}{" "}
          минут
          <Button variant="outlined" onClick={deleteAllMessages}>Удалить все сообщения</Button>
        </p>
      )}
      <DataGrid
        rows={loading ? [] : rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        components={{
          Toolbar,
          NoRowsOverlay,
          LoadingOverlay,
        }}
        style={{ minHeight: "500px" }}
        autoHeight
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: "publish_datetime", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
};
