import { useContext, useCallback, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../context";

export interface IPaginationParams {
  page: number;
  size: number;
}

export interface IChannelFilter {
  title: string;
  link: string;
  type: string;
}

export interface ISaveChannel {
  id?: string;
  title: string;
  link: string;
  type: string;
}

interface IFetchChannelProps {
  channel_type: "telegram" | "rss";
  title?: string;
  size?: number;
  page?: number;
}

export const getValidLink = (link: string) =>
  link.startsWith("http") ? link : "http://" + link;

export const useChannels = () => {
  const navigate = useNavigate();
  const { token, setRssChannels, setTelegramChannels } =
    useContext(UserContext);

  const fetchChannels = useCallback(
    (props: IFetchChannelProps) => {
      const { channel_type, title = "", size = 10000, page = 1 } = props;

      const queryParams = `channel_type=${channel_type}&size=${size}&page=${page}${
        title ? `&title=${title}` : ""
      }`;

      const fetching = async () => {
        try {
          const response = await fetch(`/api/channel?${queryParams}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          if (response.status === 401) {
            localStorage.removeItem("token");
            navigate("/login");
          }
          const data = await response.json();
          return data;
        } catch (e) {
          navigate("/");
          console.error(e);
        }
      };

      return fetching();
    },
    [navigate, token]
  );

  const saveChannel = async (props: ISaveChannel) => {
    const body = JSON.stringify(props);

    try {
      const response = await fetch("/api/channel", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body,
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const deleteChannel = async (id: string) => {
    try {
      const response = await fetch(`/api/channel/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const updateChannel = async (props: ISaveChannel) => {
    const body = JSON.stringify(props);

    try {
      const response = await fetch("/api/channel", {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body,
      });
      const data = await response.json();
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchTelegramChannels = useCallback(
    (setLoading: Dispatch<SetStateAction<boolean>>) => {
      const fetch = async () => {
        setLoading(true);
        try {
          const data = await fetchChannels({
            channel_type: "telegram",
          });

          if (data?.details) {
            navigate("/login");
          }

          return data;
        } catch (e) {
          console.error(e);
        }
      };

      fetch().then((response) => {
        setLoading(false);
        setTelegramChannels(response);
      });
    },
    [fetchChannels, navigate, setTelegramChannels]
  );

  const fetchRssChannels = useCallback(
    (setLoading: Dispatch<SetStateAction<boolean>>) => {
      const fetch = async () => {
        setLoading(true);

        try {
          const data = await fetchChannels({
            channel_type: "rss",
          });
          if (data?.details) {
            navigate("/login");
          }

          return data;
        } catch (e) {
          console.error(e);
        }
      };

      fetch().then((response) => {
        setLoading(false);
        setRssChannels(response);
      });
    },
    [fetchChannels, navigate, setRssChannels]
  );

  return {
    saveChannel,
    updateChannel,
    deleteChannel,
    fetchChannels,
    fetchRssChannels,
    fetchTelegramChannels,
  };
};
